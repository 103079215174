import React from 'react'
import control, { alertDanger, form, local, modal, setLocal } from '../../../core/core'
import Table from '../../../components/table/table'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import { Pencil } from 'react-ionicons'

import { AiOutlinePlus } from 'react-icons/ai'
import EditarContenido from '../editarContenido/editarContenido'


export default class Contenidos extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: true,
            contenidos: [],

            terminoBusqueda: '',
            api: 'admin/contenidos/contenidos'
        }

        this.handleUpdate = this.handleUpdate.bind(this)
    }


    handleUpdate(d) {
        let contenidos = this.state.contenidos
        if (d.status.nuevo) {
            contenidos.push(d.item)
        } else {
            let i = contenidos.findIndex(e => parseFloat(e.id) === d.item.id)

            if (i !== -1) {
                contenidos[i] = d.item
            }
        }

        this.setState({ contenidos })
    }



    async componentDidMount() {
        const terminoBusqueda = local('contenidos_terminoBusqueda')

        if (terminoBusqueda) this.setState({ terminoBusqueda })

        const contenidos = await control({ api: this.state.api, request: 'filtrarContenidos' })

        this.setState({ contenidos: contenidos ?? [], loading: false })


    }


    setTerminoBusqueda(v) {
        this.setState({ terminoBusqueda: v })

        setLocal({ item: 'contenidos_terminoBusqueda', value: v })
    }


    async editarContenido(id) {
        modal({
            header: 'Editar contenido',
            contenido: <EditarContenido handleUpdate={this.handleUpdate} idContenido={id} />
        })
    }



    render() {

        let contenidos = this.state.contenidos ?? []

        let parentsUno = contenidos && contenidos.length > 0 ? contenidos.filter(e => parseFloat(e.parent) === 0) : []
        let arrayParentsUno = parentsUno.map(e => {
            return e.id
        })

        let parentsDos = contenidos.filter(p => parseFloat(p.parent) === parseFloat(this.state.parentUno))
        let parentsTres = undefined,
            arrayParentsDos = [],
            arrayParentsTres = []


        if (parentsDos && parentsDos.length > 0) {
            arrayParentsDos = parentsDos.map(e => {
                return e.id
            })




            parentsTres = contenidos.filter(p => {
                if (this.state.parentDos) return parseFloat(p.parent) === parseFloat(this.state.parentDos)
                return arrayParentsDos.indexOf(p.parent) !== -1
            })




            if (parentsTres && parentsTres.length > 0) {
                arrayParentsTres = parentsTres.map(e => {
                    return e.id
                })

            }
        }





        if (this.state.parentTres && parseFloat(this.state.parentTres)) {
            contenidos = contenidos.filter(e => e.parent === this.state.parentTres)

        } else if (this.state.parentDos && parseFloat(this.state.parentDos)) {
            contenidos = parentsTres
        } else if (this.state.parentUno && parseFloat(this.state.parentUno)) {
            contenidos = parentsDos
        } else {
            contenidos = contenidos.filter(e => e.parent === '0')

        }



        if (this.state.terminoBusqueda !== '') contenidos = contenidos.filter(e => e.title_es.toLowerCase().indexOf(this.state.terminoBusqueda.toLowerCase()) !== -1)


        return (

            <>
                <div className="grid5">
                    <div className="inputSection wrap">
                        <label htmlFor="">Buscar por palabras</label>

                        <input type="text" id="terminoBusqueda" placeholder="Ingresa un termino de busqueda..." value={this.state.terminoBusqueda} onChange={e => {
                            this.setState({ terminoBusqueda: e.target.value })
                        }} />
                    </div>
                    <div className="inputSection wrap">
                        <label htmlFor="">Super categoría</label>

                        <select id="parentUno" value={this.state.parentUno} onChange={e =>
                            this.setState({ parentUno: e.target.value })
                        }>
                            <option value="0">Selecciona una opción</option>
                            {
                                parentsUno.map(e => {
                                    return <option value={e.id}>{e.title_es}</option>
                                })
                            }
                        </select>

                    </div>
                    <div className="inputSection wrap">
                        <label htmlFor="">Categoría</label>

                        <select id="parentDos" value={this.state.parentDos} onChange={e =>
                            this.setState({ parentDos: e.target.value })
                        }>
                            <option value="0">Selecciona una opción</option>
                            {
                                parentsDos && parentsDos.length > 0 ? parentsDos.map(e => {
                                    return <option value={e.id}>{e.title_es}</option>
                                }) : <option disabled={true}>Debes filtrar por super categoría primero</option>
                            }
                        </select>

                    </div>
                    <div className="inputSection wrap">
                        <label htmlFor="">Categoria inferior</label>

                        <select id="parentTres" value={this.state.parentTres} onChange={e =>
                            this.setState({ parentTres: e.target.value })
                        }>
                            <option value="0">Selecciona una opción</option>
                            {
                                parentsTres && parentsTres.length > 0 ? parentsTres.map(e => {
                                    return <option value={e.id}>{e.title_es}</option>
                                })
                                    : <option disabled={true}>Debes filtrar por categoría primero</option>
                            }
                        </select>

                    </div>

                    <div className="inputSection wrap">
                        <button type="button" className="primary" style={{ marginTop: '25px' }} onClick={() => this.setState({ terminoBusqueda: '', parentUno: 0, parentDos: 0, parentTres: 0 })}>
                            Reset filtros
                        </button>
                    </div>


                </div>
                <div className="appElement">
                    <button type="button" className="success" onClick={() => this.editarContenido(0)}>
                        <AiOutlinePlus /> Nuevo
                    </button>

                    {this.state.loading ? (
                        <RoundSpinner />
                    ) : (
                        Table({
                            thead: [
                                {
                                    title: 'ID'
                                },
                                {
                                    title: 'Titulo'
                                },
                                {
                                    title: ''
                                }
                            ],
                            tbody: contenidos.length > 0 ? (
                                contenidos.map(e => {
                                    return <tr>
                                        <td>
                                            {e.id}
                                        </td>
                                        <td>
                                            {e.title_es}
                                        </td>
                                        <td>
                                            <button className="outlineSuccess" onClick={() => this.editarContenido(e.id)} >
                                                <Pencil />

                                            </button>
                                        </td>
                                    </tr>
                                })
                            ) : (
                                <tr>
                                    <td>No hay resultados que mostrar</td>
                                </tr>
                            )
                        })

                    )

                    }

                </div>

            </>



        )
    }
}