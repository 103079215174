import React from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import control, { alertDanger, alertSuccess, form, ge } from '../../../core/core'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner';
import { SaveOutline } from 'react-ionicons';
import { FaRegTrashAlt } from 'react-icons/fa'
export default class EditarContenido extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            contenido: {
                id: 0,
                title_en: '',
                title_es: '',
                content_en: '',
                content_es: '',
                photos: '',
                animation: '',
                tables: '',
                related_ids: '',
                parent: '',
                type: '',
                glossary: []

            },
            newGlossary: {
                es: '',
                en: '',
                ref: ''
            },
            editor: {
                content_en: EditorState.createEmpty(),
                content_es: EditorState.createEmpty(),
            },
            api: 'admin/contenidos/contenidos'
        }

        this.handleInputContenido = this.handleInputContenido.bind(this)
        this.handleInputGlossary = this.handleInputGlossary.bind(this)



        this.handleUpdate = this.props.handleUpdate

    }


    setEditor(k, v, setContenido = false) {
        this.setState(ps => ({
            ...ps,
            editor: {
                ...ps.editor,
                [k]: v
            }
        }))

        if (setContenido) this.setInputContenido(k, draftToHtml(convertToRaw(v.getCurrentContent())))

    }



    async editarContenidoSubmit(e) {
        e.preventDefault()

        const status = await form({ api: this.state.api, form: 'editarContenido' })

        if (!status.status) return alertDanger('No se ha podido actualizar')


        let mensaje = ''
        if (status.nuevo) {
            this.setInputContenido('id', status.nuevo)
            mensaje = ' creado '

        } else {
            mensaje = ' actualizado '
        }


        alertSuccess(`Contenido ${mensaje} con éxito`)

        this.handleUpdate({
            status,
            item: {
                id: parseFloat(this.state.contenido.id),
                title_es: this.state.contenido.title_es
            }
        })
    }



    async componentDidMount() {
        let contenido = false
        if (parseFloat(this.props.idContenido) > 0) {
            contenido = await control({ api: this.state.api, request: 'detallesContenido', data: this.props.idContenido })


            if (contenido) {
                let content_es = convertFromHTML(contenido.content_es)
                content_es = ContentState.createFromBlockArray(
                    content_es.contentBlocks,
                    content_es.entityMap
                )
                content_es = EditorState.createWithContent(content_es)

                let content_en = convertFromHTML(contenido.content_en)
                content_en = ContentState.createFromBlockArray(
                    content_en.contentBlocks,
                    content_en.entityMap
                )
                content_en = EditorState.createWithContent(content_en)



                this.setEditor('content_en', content_en)
                this.setEditor('content_es', content_es)


                if (contenido.glossary) contenido.glossary = JSON.parse(contenido.glossary)

            }

        }

        await this.setState({ contenido: contenido ?? {}, loading: false })



    }
    handleInputGlossary(e) {
        let element = e.target,
            value = element.value

        if (element.type === 'checkbox') value = element.checked



        this.setState(prevState => ({
            ...prevState,
            newGlossary: {
                ...prevState.newGlossary,
                [element.id]: value
            }
        }))


    }

    handleInputContenido(e) {
        let element = e.target,
            value = element.value

        if (element.type === 'checkbox') value = element.checked



        this.setInputContenido(element.id, value)
    }


    setInputContenido(k, v) {
        this.setState(prevState => ({
            ...prevState,
            contenido: {
                ...prevState.contenido,
                [k]: v
            }
        }))
    }


    addGlossary() {
        let newGlossary = this.state.newGlossary
        if (newGlossary.es === '') return alertDanger('Por favor ingresa la frase en español a enlazar')
        if (newGlossary.en === '') return alertDanger('Por favor ingresa la frase en inglés a enlazar')
        if (newGlossary.ref === '') return alertDanger('Por favor ingresa la referencia de la definición a enlazar')

        let glossary = this.state.contenido.glossary ?? []

        glossary.push(newGlossary)


        this.setInputContenido('glossary', glossary)

        this.setState({
            newGlossary: {
                es: '',
                en: '',
                ref: ''
            }
        })
    }


    removeGlossary(ref) {
        let glossary = this.state.contenido.glossary.filter(e => e.ref !== ref)

        this.setInputContenido('glossary', glossary)
    }

    render() {
        return (

            this.state.loading ? (
                <RoundSpinner />
            ) : (

                <>
                    <form id="editarContenido" onSubmit={e => this.editarContenidoSubmit(e)}>
                        <input type="hidden" id="id" value={this.state.contenido.id} />

                        <div className="grid2">
                            <div className="inputSection wrap">
                                <label htmlFor="title_es">Titulo en español</label>
                                <input type="text" id="title_es" value={this.state.contenido.title_es} onChange={this.handleInputContenido} />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="title_en">Titulo en inglés</label>
                                <input type="text" id="title_en" value={this.state.contenido.title_en} onChange={this.handleInputContenido} />
                            </div>




                        </div>


                        <hr />

                        <div className="inputSection wrap">
                            <label htmlFor="content_es">Contenido en español</label>

                            <Editor
                                editorState={this.state.editor.content_es}
                                editorClassName="editorQwerty"
                                placeholder="Contenido en español"
                                onEditorStateChange={e => this.setEditor('content_es', e, true)}
                            />
                            <textarea className="hidden" id="content_es" value={this.state.contenido.content_es} />


                        </div>

                        <hr />

                        <div className="inputSection wrap">
                            <label htmlFor="content_en">Contenido en Inglés</label>
                            <Editor
                                editorState={this.state.editor.content_en}
                                editorClassName="editorQwerty"
                                placeholder="Contenido en inglés"
                                onEditorStateChange={e => this.setEditor('content_en', e, true)}
                            />
                            <textarea className="hidden" id="content_en" value={this.state.contenido.content_en} />

                        </div>

                        <div className="grid3">


                            <div className="inputSection wrap">
                                <label htmlFor="photos">Fotos</label>
                                <input type="text" id="photos" value={this.state.contenido.photos} onChange={this.handleInputContenido} />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="animation">Animación</label>
                                <input type="text" id="animation" value={this.state.contenido.animation} onChange={this.handleInputContenido} />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="tables">Tablas</label>
                                <input type="text" id="tables" value={this.state.contenido.tables} onChange={this.handleInputContenido} />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="related_ids">Ids relacionados</label>
                                <input type="text" id="related_ids" value={this.state.contenido.related_ids} onChange={this.handleInputContenido} />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="parent">Padre</label>
                                <input type="text" id="parent" value={this.state.contenido.parent} onChange={this.handleInputContenido} />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="type">Tipo</label>
                                <input type="text" id="type" value={this.state.contenido.type} onChange={this.handleInputContenido} />
                            </div>


                        </div>


                        <textarea className="hidden" id="glossary" value={this.state.contenido.glossary ? JSON.stringify(this.state.contenido.glossary) : '[]'} />


                        <button type="submit" className="hidden" id="submitContenido">
                            Guardar
                        </button>
                    </form>


                     <h2>
                        Glossary:
                    </h2>
                    <div className="grid1">

                        <table>
                            <tbody>

                                <tr>
                                    <td>
                                        <div className="inputSection wrap">
                                            <label htmlFor="es">Español</label>
                                            <input type="text" name="es" id="es" placeholder="Frase o palabra en español" value={this.state.newGlossary.es} onChange={this.handleInputGlossary} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="inputSection wrap">
                                            <label htmlFor="en">Inglés</label>
                                            <input type="text" name="en" id="en" placeholder="Frase o palabra en inglés" value={this.state.newGlossary.en} onChange={this.handleInputGlossary} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="inputSection wrap">
                                            <label htmlFor="es">Referencia</label>
                                            <input type="text" name="ref" id="ref" placeholder="Referencia del contenido a enlazar" value={this.state.newGlossary.ref} onChange={this.handleInputGlossary} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="inputSection wrap">
                                            <label htmlFor="addGlossary"></label>
                                            <button type="button" id="addGlossary" className="outlineSuccess" onClick={() => this.addGlossary()} >
                                                <SaveOutline />
                                            </button>
                                        </div>
                                    </td>
                                </tr>


                            </tbody>
                        </table>



                        <div className="glossaryList grid1">
                            {
                                this.state.contenido.glossary && this.state.contenido.glossary.length > 0 ? (
                                    <table>
                                        <tbody>
                                            {
                                                this.state.contenido.glossary.reverse().map(e => {
                                                    return (
                                                        <tr  className="center">
                                                           
                                                            <td>
                                                                {
                                                                    e.es
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    e.en
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    e.ref
                                                                }
                                                            </td>
                                                            <td>
                                                                <button type="button" id="addGlossary" className="outlineDanger" onClick={() => this.removeGlossary(e.ref)} >
                                                                    <FaRegTrashAlt />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    </div> 



                    <button type="button" className="success" onClick={() => ge('#submitContenido').click()}>
                        Guardar
                    </button>
                </>
            )

        )
    }
}