export default function appInit(){
   eventListeners()
      
}

function eventListeners(){
    document.addEventListener('DOMSubtreeModified', () => {
    
    
    })
}