const urlBase = 'api.weathercyclopedia.com'



const crud = `//${urlBase}/control/control.php`


export const cdn = `//cdn.${urlBase}/`



export default crud