import React from 'react'

import control, { alertDanger, alertSuccess, form } from '../../../core/core'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner';
import headerSection from '../../../components/headerSection';

export default class EditarGlossary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            glossary: {
                id: 0,
                title_en: '',
                title_es: '',
                content_en: '',
                content_es: '',
                link_ref: '',
                letter_es: '',
                letter_en: '',

            },
            api: 'admin/glossary/glossary'
        }

        this.handleInputGlossary = this.handleInputGlossary.bind(this)



        this.handleUpdate = this.props.handleUpdate

    }




    async editarGlossarySubmit(e) {
        e.preventDefault()

        const status = await form({ api: this.state.api, form: 'editarGlossary' })

        if (!status.status) return alertDanger('No se ha podido actualizar')


        let mensaje = ''
        if (status.nuevo) {
            this.setInputGlossary('id', status.nuevo)
            mensaje = ' creado '

        } else {
            mensaje = ' actualizado '
        }


        alertSuccess(`Glossary ${mensaje} con éxito`)

        this.handleUpdate({
            status,
            item: {
                idGlossary: parseFloat(this.state.glossary.idGlossary),
                title_es: this.state.glossary.title_es,
                title_en: this.state.glossary.title_en
            }
        })
    }



    async componentDidMount() {
        let glossary = false
        if (parseFloat(this.props.idGlossary) > 0) {
            glossary = await control({ api: this.state.api, request: 'detallesGlossary', data: this.props.idGlossary })

        }

        await this.setState({ glossary: glossary ?? {}, loading: false })



    }
    handleInputGlossary(e) {
        let element = e.target,
            value = element.value

        if (element.type === 'checkbox') value = element.checked



        this.setInputGlossary(element.id, value)
    }


    setInputGlossary(k, v) {
        this.setState(prevState => ({
            ...prevState,
            glossary: {
                ...prevState.glossary,
                [k]: v
            }
        }))
    }



    render() {
        return (

            this.state.loading ? (
                <RoundSpinner />
            ) : (

                <>
                    {
                        parseFloat(this.state.glossary.idGlossary) > 0 ? (
                            headerSection('Editar glossary número ' + this.state.glossary.idGlossary)
                        ) : (
                            headerSection('Crear nuevo')

                        )
                    }
                    <form id="editarGlossary" onSubmit={e => this.editarGlossarySubmit(e)}>
                        <input type="hidden" id="idGlossary" value={this.state.glossary.idGlossary} />

                        <div className="grid2">
                            <div className="inputSection wrap">
                                <label htmlFor="title_es">Titulo en español</label>
                                <input type="text" id="title_es" value={this.state.glossary.title_es} onChange={this.handleInputGlossary} />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="title_en">Titulo en inglés</label>
                                <input type="text" id="title_en" value={this.state.glossary.title_en} onChange={this.handleInputGlossary} />
                            </div>




                        </div>


                        <hr />

                        <div className="grid2">

                            <div className="inputSection wrap">

                                <label htmlFor="content_es">Glossary en español</label>

                                <textarea id="content_es" value={this.state.glossary.content_es} onChange={this.handleInputGlossary} rows={6} />


                            </div>


                            <div className="inputSection wrap">
                                <label htmlFor="content_en">Glossary en Inglés</label>
                                <textarea id="content_en" value={this.state.glossary.content_en} onChange={this.handleInputGlossary} rows={6} />

                            </div>


                        </div>


                        <hr />

                        <div className="grid3">

                            <div className="inputSection wrap">
                                <label htmlFor="letter_es">Letra en español</label>
                                <input type="text" id="letter_es" value={this.state.glossary.letter_es} onChange={this.handleInputGlossary} />
                            </div>
                            <div className="inputSection wrap">
                                <label htmlFor="letter_en">Letra en inglés</label>
                                <input type="text" id="letter_en" value={this.state.glossary.letter_en} onChange={this.handleInputGlossary} />
                            </div>
                            <div className="inputSection wrap">
                                <label htmlFor="ref">Link referencia</label>
                                <input type="text" id="ref" value={this.state.glossary.ref} onChange={this.handleInputGlossary} />
                            </div>

                        </div>



                        <button type="submit" className="success">
                            Guardar
                        </button>
                    </form>



                </>
            )

        )
    }
}