import React from 'react'
import {  NavLink } from 'react-router-dom'
import brandLogo from '../../components/bandLogo'
import { logOut } from '../../core/core'
import './navTop.css'


export default class NavTop extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            isLogged: false,
            _t: parseFloat(this.props._t)
        }
    }

    componentDidMount() {

        this.setState({ loading: false })
    }


    render() {

        return (

            <>
                <div className="topBar">
                    <div className="topBarMenuContainer">
                        <div className="brand">
                            {brandLogo()}
                        </div>
                        <div className="topBarMenu">

                        </div>

                    </div>


                    <div className="topBarMenu">
                        <div className="topNavMenuElement" onClick={() => logOut()}>
                            Salir
                        </div>
                    </div>
                </div>

                <div className="topBarNav">
                    <NavLink to="/contenidos" activeClassName="topNavActive">
                        <div className="topBarNavElement">
                            Contenidos
                        </div>
                    </NavLink>
                    <NavLink to="/glossary" activeClassName="topNavActive">
                        <div className="topBarNavElement">
                            Glossary
                        </div>
                    </NavLink>
                    <NavLink to="/mi-perfil" activeClassName="topNavActive">
                        <div className="topBarNavElement">
                            Mi perfil
                        </div>
                    </NavLink>
                </div>
            </>

        )
    }
}