import React from "react";
import Login from "../login/login";

export default class Home extends React.Component {




    render() {

        return (

             <Login/>



        )
    }
}