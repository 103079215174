import React from 'react'
import { alert, form, setSesion } from '../../../core/core'


export default class Login extends React.Component {

    async procesarLogin(e) {
        e.preventDefault()

        const login = await form({ 'api': 'auth/login', 'form': 'login', 'event': e })
        if (login) {
            
            setSesion(login,true)

        } else {
            alert({ 'tipo': 0, 'mensaje': 'Comprueba los campos' })
        }
    }

    render() {
        return (
            <div className="loginContainer appElement">
                <form id="login" onSubmit={e => this.procesarLogin(e)}>
                    <div className="inputSection inputWithIcon top35">
                        <label htmlFor="email"><i class="fas fa-user"></i></label>
                        <input type="email" id="email" placeholder="Nombre de usuario/Email" required />
                    </div>
                    <div className="inputSection inputWithIcon">
                        <label htmlFor="password"><i class="fas fa-lock"></i></label>
                        <input type="password" id="password" placeholder="Contraseña" required />
                    </div>
                    <div className="inputSection">
                        <input type="checkbox" id="remember"></input>
                        <span>Recuérdame</span>
                    </div>
                    <div className="inputSection">
                        <button type="submit" className="primary btnLg btnRounded">Entrar</button>
                    </div>


                </form>
            </div>
        )
    }

}