import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './views/home/home'

export default function PublicSide() {

    return (

        <Router>
            <Switch>
                <Route exact path="/" >
                    <Home />
                </Route>
            

            </Switch>
        </Router>

    )
}