import React from 'react'
import { alertDanger, form, local, modal, setLocal } from '../../../core/core'
import Table from '../../../components/table/table'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import { Pencil } from 'react-ionicons'

import { AiOutlinePlus } from 'react-icons/ai'
import EditarGlossary from '../editarGlossary/editarGlossary'


export default class Glossarys extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: true,
            glossarys: [],
            
            terminoBusqueda: '',
            api: 'admin/glossary/glossary'
        }

        this.handleUpdate = this.handleUpdate.bind(this)
    }
    handleUpdate(d){
        let glossarys = this.state.glossarys
        if(d.status.nuevo){
            glossarys.push(d.item)
        }else{
            let i = glossarys.findIndex(e => parseFloat(e.idGlossary) === d.item.idGlossary)

            if(i !== -1){
                glossarys[i] = d.item
            }
        }


        this.setState({glossarys})
    }
    
    async componentDidMount() {
        const terminoBusqueda = local('glossarys_terminoBusqueda')

        if (terminoBusqueda) await this.setState({ terminoBusqueda })
        this.filtrarGlossary(false,true)
    }

    async filtrarGlossary(e,force = false) {
        if (e) e.preventDefault()

        if (!force && this.state.terminoBusqueda.length < 3) return false


        const glossarys = await form({ api: this.state.api, form: 'filtrarGlossary' })

        if (!glossarys) return alertDanger('No se encontraron resultados')

        this.setState({ glossarys })

        if (this.state.loading) this.setState({ loading: false })

    }




    setTerminoBusqueda(v) {
        this.setState({ terminoBusqueda: v })

        setLocal({ item: 'glossarys_terminoBusqueda', value: v })
    }


    async editarGlossary(id) {
        modal({
            header: 'Editar elemento',
            contenido: <EditarGlossary  handleUpdate={this.handleUpdate} idGlossary={id}/>
        })
    }



    render() {
        return (

            <>
                <form id="filtrarGlossary" onSubmit={e => this.filtrarGlossary(e)}>

                    <div className="grid3">
                        <div className="inputSection wrap">
                            <button type="button" className="success" onClick={() => this.editarGlossary(0)}>
                                <AiOutlinePlus /> Nuevo
                            </button>
                        </div>
                        <div className="inputSection wrap">
                            <input type="text" id="terminoBusqueda" placeholder="Ingresa un termino de busqueda..." value={this.state.terminoBusqueda} onChange={e => {
                                this.setTerminoBusqueda(e.target.value)
                                this.filtrarGlossary()
                            }} />
                        </div>
                        <div className="inputSection wrap">
                            <button type="button" id="glossarysSubmit" className="primary" onClick={() => this.filtrarGlossary(false,true)}>
                                Filtrar
                            </button>
                        </div>
                    </div>
                </form>
                <div className="appElement">

                    {this.state.loading ? (
                        <RoundSpinner />
                    ) : (
                        Table({
                            thead: [
                                {
                                    title: 'ID'
                                },{
                                    title: 'Titulo ES'
                                },
                                {
                                    title: 'Titulo EN'
                                },
                                {
                                    title: ''
                                }
                            ],
                            tbody: this.state.glossarys.length > 0 ? (
                                this.state.glossarys.map(e => {
                                    return <tr>
                                        <td>
                                            {e.idGlossary}
                                        </td>
                                        <td>
                                            {e.title_es}
                                        </td>
                                        <td>
                                            {e.title_en}
                                        </td>
                                        <td>
                                            <button className="outlineSuccess" onClick={() => this.editarGlossary(e.idGlossary)} >
                                                <Pencil />

                                            </button>
                                        </td>
                                    </tr>
                                })
                            ) : (
                                <tr>
                                    <td>No hay resultados que mostrar</td>
                                </tr>
                            )
                        })

                    )

                    }

                </div>

            </>



        )
    }
}