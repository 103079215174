import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import control, { local, logOut } from '../core/core'
import Login from '../publicSide/views/login/login'
import NavTop from './navTop/navTop'
import './privateSide.css'
import Contenidos from './views/contenidos/contenidos'
import Glossarys from './views/glossarys/glossarys'
import MiPerfil from './views/mi-perfil/mi-perfil'



export default class PrivateSide extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            isLogged: this.props.isLogged,
            sessionStatus: false,
            _t: 0
        }

    }


    async componentDidMount() {

        if (!this.state.isLogged) return logOut()

        const checkSession = await control({ api: 'security/seguridad', request: 'checkSession' })

        if (!checkSession) return logOut()

        this.setState({ sessionStatus: true, _t: local('_t'), loading: false })

    }


    render() {

        return (
            this.state.loading ? 'cargando' :
                (
                    this.state.isLogged && this.state.sessionStatus ? (
                        <>

                            <Router>
                                <NavTop />

                                <Switch>
                                    <Route exact path="/" >
                                        <Contenidos />
                                    </Route >

                                    <Route exact path="/contenidos" >
                                        <Contenidos />
                                    </Route >

                                    <Route exact path="/glossary" >
                                        <Glossarys />
                                    </Route >

                                    <Route exact path="/mi-perfil" >
                                        <MiPerfil />
                                    </Route >

                                </Switch >
                            </Router >
                        </>
                    ) : (
                        <Login />

                    )
                )

        )
    }
}