import './styles/style.css';
import { local } from './core/core';
import PublicSide from './publicSide/publicSide';
import PrivateSide from './privateSide/privateSide';

function App() {

  const isLogged = local('auth')
  return (
  isLogged ? <PrivateSide isLogged={isLogged}/> : <PublicSide/>    
  )
}

export default App;
